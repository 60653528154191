import { useRef, forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { calculateAlphaColor } from "utils/colors";

import ReactJWPlayer from "react-jw-player";
import { CaretRightOutlined } from "@ant-design/icons";
import useWindowSize from "hooks/utils/useWindowSize";
import styled from "styled-components";
import { sendDatas } from "core/store/actions";

const bkg2 = require("assets/images/onepage/video/earth.jpg");

const DIV_ID = "bee2link-video-player";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size?.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 64px;
`;

const ButtonY = styled.button`
  background-color: transparent;
  border: 3px solid ${(props) => props.theme.colors.primary.background};
  border-radius: 12px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary.background};
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: all 0.8s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  :hover {
    color: ${(props) => props.theme.colors.primary.foreground};
    background-color: ${(props) => props.theme.colors.primary.background};
  }
`;

const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background: #010101;
`;

const HomePage = forwardRef((props, ref) => {
  const { onPlayVideo } = props;
  const [showVideo, setShowVideo] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector((state) => state.fuzio.token);

  const uuid = useSelector((state) => state.fuzio.uuid);
  const lang = useSelector((state) => state.fuzio.lang);
  const deviceType = useSelector((state) => state.fuzio.deviceType);

  const videoElement = useRef(null);
  let jwPlayer = null;
  //const content = useSelector((state) => state.fuzio.content);
  //const currentPage = content[lang].pages.find((el) => el.key === id).content;

  //const video = currentPage.video;

  //const file = "https://cosmolib.fr/_media/Fuzio-movie_x264_SD.mp4";
  const file = "https://cosmolib.eu/_media/cosmolib.mp4";
  //const file = "https://fuzio-project.io/_media/cosmobilis.mov";
  const sendDatasToWs = (duree_video) => {
    const datas = {
      token,
      //url: location.pathname,
      event: "video_earth",
      eventValue: duree_video,
      uuid,
      lang,
      deviceType,
    };
    //dispatch(sendDatas(datas));
  };

  const onError = (event) => {
    console.log("onError:", event);
    sendDatasToWs(`error ${event.code}`);
  };
  const onPlay = (e) => {
    onPlayVideo();
    sendDatasToWs(0);
  };
  const onTwentyFivePercent = () => {
    sendDatasToWs(25);
  };
  const onFiftyPercent = () => {
    sendDatasToWs(50);
  };
  const onSeventyFivePercent = () => {
    sendDatasToWs(75);
  };

  const onOneHundredPercent = () => {
    sendDatasToWs(100);
  };

  const size = useWindowSize();

  useImperativeHandle(ref, () => ({
    stop() {
      window?.jwplayer(DIV_ID).stop();
    },
  }));

  const onReady = () => {
    jwPlayer = window.jwplayer(DIV_ID);
  };

  const onResume = () => {
    console.log("onResume");
    onPlayVideo();
  };
  const onExitFullScreen = () => {
    window.jwplayer(DIV_ID).stop();
    setShowVideo(false);
  };

  const playFullScreen = () => {
    if (videoElement.current) {
      setShowVideo(true);
      window.jwplayer(DIV_ID).setFullscreen(true);
      window.jwplayer(DIV_ID).play();
      onPlayVideo();
      //videoElement.current.props.playerId.play();
    }
  };
  return (
    <>
      <ButtonY
        type="primary"
        size="large"
        icon={<CaretRightOutlined />}
        onClick={playFullScreen}
        style={{ marginTop: 30 }}
      >
        <CaretRightOutlined style={{ fontSize: 30 }} />{" "}
        {intl.formatMessage({ id: "views.medias.ButtonPlayFullScreen1" })}
      </ButtonY>

      {file && (
        <VideoWrapper
          size={size}
          style={{ display: showVideo ? "block" : "none" }}
        >
          <ReactJWPlayer
            ref={videoElement}
            playerId={DIV_ID}
            playerScript="https://cdn.jwplayer.com/libraries/GWqxTRnu.js"
            playlist={[
              {
                file: `${file}?token=${token}`,
                type: "mp4",
                image: bkg2,
              },
            ]}
            onExitFullScreen={onExitFullScreen}
            onResume={onResume}
            onError={onError}
            onPlay={onPlay}
            onTwentyFivePercent={onTwentyFivePercent}
            onFiftyPercent={onFiftyPercent}
            onSeventyFivePercent={onSeventyFivePercent}
            onOneHundredPercent={onOneHundredPercent}
            onReady={onReady}
          />
        </VideoWrapper>
      )}
    </>
  );
});

/*
     <Button
              type="primary"
              size="large"
              icon={<CaretRightOutlined />}
              onClick={playFullScreen}
            >
              {intl.formatMessage({ id: "views.medias.ButtonPlayFullScreen1" })}
            </Button>
*/
export default HomePage;
