import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/utils/useWindowSize";
import { isMobile } from "react-device-detect";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Space } from "antd";

import { ReactComponent as Car } from "assets/svg/Car.svg";
import { ReactComponent as Shop } from "assets/svg/Shop.svg";
import { ReactComponent as Share2 } from "assets/svg/Share2.svg";
import { ReactComponent as CarRepair } from "assets/svg/CarRepair.svg";
import { ReactComponent as CarElectric } from "assets/svg/CarElectric.svg";
import { ReactComponent as Cosmobilis } from "assets/svg/Cosmobilis.svg";

import { ReactComponent as Bubbles } from "assets/svg/Bubbles.svg";
import { toggleMenuColor } from "core/store/actions";
import VideoApp from "./video_earth";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size?.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.primary.foreground};
  color: ${(props) => props.theme.colors.primary.foreground};
`;

const Title = styled.h1`
  font-size: ${isMobile ? 45 : 120}px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary.background};
  span {
    color: #5697db;
  }
`;

const SubTitle = styled.p`
  font-size: ${isMobile ? 18 : 40}px;
  font-weight: 100;
  span {
    font-weight: 600;
  }
  margin: 0 0 ${isMobile ? 50 : 100}px 0;
  color: ${(props) => props.theme.colors.primary.background};
  gap: 10px;
  display: flex;
`;

const BubbleWapper1 = styled.div`
  position: absolute;
  top: ${isMobile ? -10 : 30}%;
  left: 10%;
  height: ${isMobile ? 100 : 200}px;
  margin-top: -${isMobile ? 50 : 100}px;
  margin-left: -100px;
`;

const BubbleWapper2 = styled.div`
  position: absolute;
  bottom: ${isMobile ? 10 : 30}%;
  right: 10%;
  height: ${isMobile ? 100 : 200}px;
  margin-top: -${isMobile ? 50 : 100}px;
  margin-right: -100px;
  z-index: 1;
`;

const Logo = styled(Cosmobilis)`
  width: ${isMobile ? 200 : 400}px;
  fill: ${(props) => props.theme.colors.primary.background};
`;

const IconsWrapper = styled.div``;

function Home() {
  const intl = useIntl();
  const size = useWindowSize();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(true));
  }, []);

  return (
    <GlobalWrapper size={size}>
      <Title>
        C O S M O <span>L I B</span>
      </Title>
      <SubTitle>
        by
        <Logo></Logo>
      </SubTitle>
      <IconsWrapper></IconsWrapper>
      <VideoApp />
      <BubbleWapper1>
        <Bubbles />
      </BubbleWapper1>
      <BubbleWapper2>
        <Bubbles />
      </BubbleWapper2>
    </GlobalWrapper>
  );
}

export default Home;
