import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";

import { sendDatas } from "core/store/actions";

import Menu from "../menu";
import GetTheDesk from "../getthedesk";
import ErrorBoundary from "../../error-boundary";
import Langs from "../langs";
import { calculateAlphaColor } from "utils/colors";
import { ROUTES } from "constants/routes";
const { Header, Content } = Layout;

const StyledHeader = styled(Header)`
  &&& {
    background: ${(props) => props.theme.colors.primary.background};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1002;
    ${isMobile && `padding-inline:10px;`}
  }
`;

const StyledLayout = styled(Layout)`
  min-height: 100%;
`;

const StyledContent = styled(Content)`
  /*min-height: 100%;*/
  background: white;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 102;
  width: 100%;
  height: 60px;
  background: ${(props) =>
    calculateAlphaColor(
      props.theme.colors.primary.background,
      props.opaque || 0.4
    )};
  color: ${(props) => props.theme.colors.primary.foreground};
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "space-between")};
  flex: 1;
  display: flex;
  padding: 0 30px;
  backdrop-filter: blur(8px);
`;
const FooterTitle = styled.h5`
  font-size: 20px;
  font-weight: 900;
  margin: 0;
`;

//
const BasicLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [hiddenContent, setHiddenContent] = useState(true);

  const token = useSelector((state) => state.fuzio.token);
  const lang = useSelector((state) => state.fuzio.lang);

  const uuid = useSelector((state) => state.fuzio.uuid);

  const deviceType = useSelector((state) => state.fuzio.deviceType);

  useEffect(() => {
    const datas = {
      token,
      url: location.pathname,
      uuid,
      lang,
      deviceType,
    };
    //dispatch(sendDatas(datas));
  }, [location]);
  useEffect(() => {
    if (location.pathname === ROUTES.NotAllowed) {
      setDisplayLocation(location);
      //return false;
    } else {
      if (location !== displayLocation) {
        setTransistionStage("fadeOut");
      }
    }
  }, [location, displayLocation]);

  return (
    <StyledLayout>
      <StyledContent id="content">
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledContent>
    </StyledLayout>
  );
};

export default BasicLayout;
/*
{location.pathname !== "/login" && (
        <StyledHeader>
          <Menu />
        </StyledHeader>
      )}
        <Footer
        center={location.pathname === "/"}
        opaque={location.pathname !== "/login"}
      ></Footer>
        <FooterTitle>C O S M (O) B I L I S</FooterTitle>
        <Langs />
        {location.pathname === "/" && <GetTheDesk />}
*/
